module.exports = {
  title: 'jimconstable.com', // Required
  author: 'Jim Constable', // Required
  description: 'Full-stack Web Developer',
  primaryColor: '#BEFBEB', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://jimconstable.com',
    github: 'https://github.com/jimconstable',
    twitter: 'https://twitter.com/jimconstable',
    linkedin: 'https://inkedin.com/',
  },
  pathPrefix: '',
  siteUrl: 'https://jimconstable.com',
};
